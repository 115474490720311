.title {
  --color: #fff;
  --color-rgb: 53, 52, 49;

  font-size: 60px;
  font-family: var(--font-name-title);
  font-weight: var(--font-weight-black);

  line-height: 54px;
  // letter-spacing: 0.08em;
  text-transform: uppercase;

  margin: 0;
  position: relative;
  display: inline-block;

  color: var(--color);

  // &::before,
  // &::after {
  //   -webkit-text-stroke-width: 0;
  //   -webkit-text-stroke-color: transparent;

  //   position: absolute;
  //   content: attr(title);

  //   color: transparent;
  //   width: fit-content;

  //   transition-property: top left;
  //   transition-duration: 0.2s;
  // }

  // &::before {
  //   top: 4px;
  //   left: 4px;
  //   color: rgba(var(--color-rgb), 0.6);
  // }

  // &::after {
  //   top: 8px;
  //   left: 8px;
  //   color: rgba(var(--color-rgb), 0.3);
  // }

  // &:hover {
  //   &::before {
  //     top: 2px;
  //     left: 2px;
  //   }

  //   &::after {
  //     top: 4px;
  //     left: 4px;
  //   }
  // }

  &--inverted {
    --color: #fff;
    --color-rgb: 242, 145, 115;
  }

  &--killua {
    --color: #fff;
    --color-rgb: 115, 242, 183;
  }

  &--gon {
    --color: #fff;
    --color-rgb: 225, 228, 81;
  }

  &--plain {
    
    &::after {
      display: none;
    }

    &::before {
      opacity: 0.3;
      font-size: 80px;

      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    &:hover::before {
      left: 0;
      top: 50%;

      font-size: 85px;
    }

    @media screen and (max-width: 770px) {
      &::before {
        opacity: 0.3;
        font-size: 80px;

        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover:before {
        left: 50%;
        top: 50%;
      }
    }

    @media screen and (max-width: 450px) {
      &::before {
        font-size: 60px;
      }
    }
  }

  &--maroon.title--plain {
    --color: #746b57;
    --color-rgb: 116, 107, 87;
  }

  &--white.title--plain {
    --color: white;
    --color-rgb: 255, 255, 255;
  }

  &--center.title--plain {
    &::before {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover:before {
      left: 50%;
      top: 50%;
    }
  }

  &--right.title--plain {
    @media screen and (min-width: 770px) {
      &::before {
        left: unset;
        right: 0;
      }

      &:hover::before {
        left: unset;
        right: 0;
      }
    }
  }

  &--big {
    font-size: 80px;
    
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(90deg, #c72626, #fda411, #fece00, #5bccea, #71c54f, #a86b41, #92d9f5);

    @media screen and (max-width: 770px) {
      font-size: 60px;
    }

    @media screen and (max-width: 450px) {
      font-size: 40px;
    }
  }

  &--faqs,
  &--partnerships {
    @media screen and (max-width: 950px) {
      &::before {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover:before {
        left: 50%;
        top: 50%;
      }
    }
  }
}