@mixin section-fade-bottom {
  position: absolute;
  bottom: 0;
  left: 0;

  content: "";
  z-index: 0;

  width: 100%;
  height: 60px;

  background-image: linear-gradient(to bottom, transparent, #20232a);
}
