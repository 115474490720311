.rainbow {
  &__character {
    position: relative;
    color: #c4c4c4;

    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  &--hovered {
    .rainbow__character {
      filter: drop-shadow(2px 2px 0 rgb(35, 42, 109));

      &:nth-of-type(1) {
        color: #c72626;
        animation-direction: alternate;
        animation-name: character-wiggle-1;
      }

      &:nth-of-type(2) {
        color: #fda411;
        animation-direction: reverse;
        animation-name: character-wiggle-2;
      }

      &:nth-of-type(3) {
        color: #fece00;
        animation-direction: normal;
        animation-name: character-wiggle-3;
      }

      &:nth-of-type(4) {
        color: #5bccea;
        animation-name: character-wiggle-1;
        animation-direction: alternate-reverse;
      }

      &:nth-of-type(5) {
        color: #71c54f;
        animation-direction: alternate;
        animation-name: character-wiggle-2;
      }

      &:nth-of-type(6) {
        color: #a86b41;
        animation-direction: reverse;
        animation-name: character-wiggle-3;
      }

      &:nth-of-type(7) {
        color: #92d9f5;
        animation-direction: normal;
        animation-name: character-wiggle-1;
      }

      &:nth-of-type(8) {
        color: #c72626;
        animation-direction: alternate-reverse;
        animation-name: character-wiggle-2;
      }

      &:nth-of-type(9) {
        color: #fda411;
        animation-direction: normal;
        animation-name: character-wiggle-3;
      }

      &:nth-of-type(10) {
        color: #fece00;
        animation-direction: alternate-reverse;
        animation-name: character-wiggle-1;
      }

      &:nth-of-type(11) {
        color: #5bccea;
        animation-direction: alternate;
        animation-name: character-wiggle-2;
      }

      &:nth-of-type(12) {
        color: #71c54f;
        animation-direction: normal;
        animation-name: character-wiggle-3;
      }

      &:nth-of-type(13) {
        color: #a86b41;
        animation-direction: alternate;
        animation-name: character-wiggle-1;
      }

      &:nth-of-type(14) {
        color: #92d9f5;
        animation-direction: reverse;
        animation-name: character-wiggle-2;
      }
    }
  }
}

@keyframes character-wiggle-1 {
  0% {
    top: 2px;
    left: 0px;
  }

  20% {
    top: 0px;
    left: -2px;
  }

  40% {
    top: 2px;
    left: 0px;
  }

  60% {
    top: -2px;
    left: 2px;
  }

  80% {
    top: 2px;
    left: 0px;
  }

  100% {
    top: -2px;
    left: 0px;
  }
}

@keyframes character-wiggle-2 {
  0% {
    top: 0px;
    left: 2px;
  }

  20% {
    top: -2px;
    left: 0px;
  }

  40% {
    top: 0px;
    left: 2px;
  }

  60% {
    top: 2px;
    left: 0px;
  }

  80% {
    top: 2px;
    left: -2px;
  }

  100% {
    top: 0px;
    left: -2px;
  }
}

@keyframes character-wiggle-3 {
  0% {
    top: 2px;
    left: 2px;
  }

  20% {
    top: 0px;
    left: -2px;
  }

  40% {
    top: 2px;
    left: 0px;
  }

  60% {
    top: 0px;
    left: -2px;
  }

  80% {
    top: -2px;
    left: 0px;
  }

  100% {
    top: 0px;
    left: 2px;
  }
}