@import "./../../theme/mixins/section-fade-top";

.footer {
  position: relative;
  scroll-snap-align: start;

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // transform: scale(-1);
    // background-image: url(./../../../public/atmospheres/waves.svg);

    background-image: url(./../../../public/about/Fantasy-5.png);
    opacity: 0.3;
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: cover;
  }

  &::after {
    content: "";
    z-index: -1;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: #2c3248;
  }


  &__container {
  @include section-fade-top;

    margin: auto;
    max-width: 1400px;
    padding: 100px 80px 80px 80px;
    .row {
      display: flex;
      flex-direction: row;
      &--first {
        justify-content: space-between;

        @media screen and (max-width: 950px) {
          flex-direction: column;
        }
      }

      &--second {
        align-items: center;
      }

      .column {
        flex: 1;

        &--partnerships {
          margin-left: 50px;

          @media screen and (max-width: 950px) {
            margin-top: 80px;
            margin-left: 0;
          }
        }
      }
    }
  }

  .copyright {
    scroll-snap-align: end;
    scroll-margin-bottom: 52px;
    
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-weight: var(--font-weight-thin);
    padding: 5px 25px;
    color: white;
    background-color: #212636;

    &__content {
      margin: 0;
    }

    &__logo {
      max-width: 50px;
    }
  }

  @media screen and (max-width: 770px) {
    &__container {
      padding: 100px 0 80px 0;
    }
  }
}

@keyframes footer-wave {
  from {
    background-position: 0;
  }

  to {
    background-position: 1440px;
  }
}
