a.link {
  position: relative;
  z-index: 0;

  color: #965b5b;
  font-weight: bold;

  &::before {
    content: '';
    z-index: -1;

    background-color: #965b5b5b;
    border-bottom: 2px solid #965b5b5b;
    
    position: absolute;
    top: 0;
    left: -4px;

    height: 100%;
    width: 0;

    transition-duration: 0.2s;
    transition-property: width;
  }
  
  &:hover {
    &::before {
      width: calc(100% + 8px);
    }
  }
}
