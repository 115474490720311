.button {
  position: relative;

  font-size: 14px;
  text-transform: uppercase;
  font-weight: var(--font-weight-black);
  font-family: 'Noto Sans Display', sans-serif;

  color: #292929;
  box-shadow: inset -2px 2px 5px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;

  border: 5px solid #292929;
  // transform: skew(-15deg);

  border-radius: 25px;

  padding: 12px 28px;
  overflow: hidden;
  cursor: pointer;

  transition-duration: 0.2s;
  transition-property: box-shadow;

  &__content {
    display: block;
  }

  &::before {
    content: '';

    position: absolute;
    left: -10px;
    top: -10px;

    border-radius: 0 100% 0 0;
    background-image: linear-gradient(to right bottom, #eaec77, #bdf3e7);

    width: 0%;
    height: calc(100% + 20px);

    transition-duration: 0.2s;
    transition-duration: border-radius width;
  }

  &:hover {
    &::before {
      border-radius: 0;
      width: calc(100% + 20px);
    }
  }

  &--secondary {
    // border-color: #ffffff;
    background-color: rgba(32, 35, 42, 0.9);
    
    &::before {
      background-image: linear-gradient(to left top, #faa4af, #f0f192);
    }
  }

  &--loli {
    border-color: #f19173;
    background-color: #ffffff;
    
    &::before {
      background-image: linear-gradient(to left top, #faa4af, #f0f192);
    }
  }

  &--big {
    padding: 14px 35px;
    font-size: 20px;
  }

  &--header {
    padding: 6px 24px;
    font-size: 12px;
    border: 2.5px solid #292929;
    color: #292929;
    border-radius: 25x;
    box-shadow: inset -1px 1px 2.5px rgba(0, 0, 0, 0.5);
  }
}