@mixin section-fade-top {
  &::after {
    position: absolute;
    top: 0;
    left: 0;

    content: "";
    z-index: 0;

    width: 100%;
    height: 60px;

    background-image: linear-gradient(to top, transparent, #20232a);
  }
}
