.faqs {
  &__title {
    margin-bottom: 40px;

    @media screen and (max-width: 950px) {
      text-align: center;
    }
  }

  .faq {
    max-width: 535px;
    margin-left: 15px;
    margin-bottom: 16px;

    color: white;

    &:last-of-type {
      margin-bottom: 0;
    }

    &__question {
      position: relative;
      font-weight: var(--font-weight-bold);
      
      &::before {
        content: '•';

        position: absolute;
        left: -15px;
      }
    }

    &__answer {
      font-weight: var(--font-weight-regular);
    }

    &__question,
    &__answer {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.08em;
    }

    .question,
    .answer {
      margin: 0;
    }

    @media screen and (max-width: 950px) {
      margin: 0 auto 16px auto;
    }
  }

  @media screen and (max-width: 770px) {
    padding: 0 50px;
  }
}