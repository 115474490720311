.container {
  margin-left: 15px;
  &:has(input:checked) {
    .play_pause_icon {
      clip-path: polygon(
        0 0,
        0 100%,
        33.33% 100%,
        33.33% 0,
        66.66% 0,
        100% 0,
        100% 100%,
        66.66% 100%,
        66.66% 0
      );
      translate: 0 0;
    }
  }
}
input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
label {
  display: grid;
  border: none;

  --w: 40px;
  width: var(--w);
  height: var(--w);
  border-radius: 50%;
  place-items: center;
  cursor: pointer;
}
.play_pause_icon {
  --w: 50%;
  width: var(--w);
  height: var(--w);
  background: white;
  translate: 0 0;
  transition: all 0.2s ease-in-out;
}
.play {
  clip-path: polygon(
    20% 0,
    20% 100%,
    90% 50%,
    90% 50%,
    90% 50%,
    90% 50%,
    90% 50%,
    90% 50%,
    90% 50%
  );
  translate: 6% 0;
}
.pause {
  clip-path: polygon(
    0 0,
    0 100%,
    33.33% 100%,
    33.33% 0,
    66.66% 0,
    100% 0,
    100% 100%,
    66.66% 100%,
    66.66% 0
  );
  translate: 0 0;
}
// .play-2 {
// 	clip-path: polygon( 20% 0, 20% 100%, 35% 90%, 35% 90%, 35% 90%, 90% 50%, 35% 10%, 35% 10%, 35% 10% );
// 	translate: 6% 0;
// }
