.header {
  position: sticky;
  z-index: 10;
  top: 0;

  padding: 0 30px;
  backdrop-filter: blur(10px);
  background-color: rgb(0,0,0,10%);

  transition-duration: 0.2s;
  transition-property: background-color;

  .content {
    display: flex;
    align-items: center;

    margin: auto;

    .left {
      display: flex;
      align-items: center;

      .logo {
        width: 55px;
      }
    }

    .right {
      margin-left: auto;
    }

    .menu-items {
      list-style-type: none;

      margin: 0;
      padding: 0 0 0 15px;

      min-width: fit-content;
      max-width: calc(100vw - 240px);

      white-space: nowrap;

      display: flex;
      align-items: center;

      .menu-item {
        display: inline;
        padding: 0 12px;
        cursor: pointer;

        font-size: 16px;
        letter-spacing: 2px;
        font-family: 'Rubik', cursive;
        font-weight: var(--font-weight-black);

        color: #fff;

        transition-duration: 0.2s;
        transition-property: color;

        vertical-align: middle;

        &:nth-of-type(1):hover {
          color: #c72626;
        }

        &:nth-of-type(2):hover {
          color: #fda411;
        }

        &:nth-of-type(3):hover {
          color: #fece00;
        }

        &:nth-of-type(4):hover {
          color: #5bccea;
        }

        &:nth-of-type(5):hover {
          color: #71c54f;
        }

        &:nth-of-type(6):hover {
          color: #a86b41;
        }

        &:nth-of-type(7):hover {
          color: #92d9f5;
        }

        &:hover {
          color: #f2c973;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 770px) {
    padding: 0 20px;

    .menu-item--desktop {
      display: none !important;
    }
  }
}