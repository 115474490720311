.wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url("../../../public/about/Home.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(8px);
  z-index: -1;
}

.center {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.pluse {
  animation: pulse 1s infinite ease-in-out alternate;
}
.logo {
  width: 260px;
}
@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}
