:root {
  --color-primary: #3556A8;
  --color-primary-rgb: 54, 86, 168;

  --color-primary-faded: #B1C4F4;
  --color-primary-faded-rgb: 177, 196, 244;

  --color-primary-shaded: #19274A;
  --color-primary-shaded-rgb: 25, 39, 74;

  --color-secondary: #a33aa0;
  --color-secondary-rgb: 163, 58, 160;

  --color-secondary-faded: #cea3cc;
  --color-secondary-faded-rgb: 206, 163, 204;

  --color-secondary-shaded: #330732;
  --color-secondary-shaded-rgb: 51, 7, 50;


  --color-text: #13151C;

  --color-dark: #0E1017;
  --color-dark-rgb: 13, 15, 23;
}
