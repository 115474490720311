.about {
  position: relative;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;

  padding: 50px 0;

  width: 100%;
  height: 120vh;

  background-color: #20232a;
  scroll-snap-align: start;
  scroll-margin-top: 52.77px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    content: "";
    z-index: 0;

    width: 100%;
    height: 100%;

    background-image: url("./../../../public/about/Home.png");
    background-size: cover;
    background-position: 75% 100%;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;

    content: "";
    z-index: 0;

    width: 100%;
    height: 60px;

    background-image: linear-gradient(to bottom, transparent, #20232a);
  }

  &__left,
  &__right {
    flex: 1;

    transition-duration: 0.2s;
    transition-property: background-color;
  }

  &__card {
    position: relative;
    margin: 80px;
    bottom: 170px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    margin-top: 60px;
  }

  &__button {
    margin-right: 10px;
  }

  &__description {
    color: white;
    text-shadow: 2px 2px 0 #6c6c6c;

    font-size: 5em;
    font-style: normal;
    max-width: 750px;
    font-weight: var(--font-weight-regular);
    font-style: italic;
    font-family: var(--font-name-title);

  }

  &__body {
    margin-top: 35px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;
    max-width: 1400px;

    & > div {
      flex: 1;
    }
  }

  @media screen and (max-width: 992px) {
    min-height: 580px;
    flex-direction: column;

    &__content {
      flex-direction: column;
    }

    &__card {
      bottom: 60px;
      text-align: center;
    }

    &__buttons {
      align-items: center;
      justify-content: center;
    }

    &__description {
      margin-top: 100px;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 770px) {
    &__card {
      margin: 100px 20px;
    }



    &__description {
      font-size: 35px;
      line-height: 40px;
    }
  }

  @media screen and (max-width: 450px) {
    &__card {
      margin: 100px 20px;
    }

    &__buttons {
      flex-direction: column;
    }

    &__button {
      margin-bottom: 15px;
    }

    &__description {
      font-size: 25px;
      line-height: 30px;
    }
  }
}
