@import "./../../theme/mixins/section-fade-bottom";
@import "./../../theme/mixins/section-fade-top";

.ecosystem {
  position: relative;
  padding: 100px 50px;
  height: 100vh;
  margin: 0 auto;
  background-color: #20232a;
  scroll-snap-align: start;
  &::before {
    content: "";
    z-index: 0;
    opacity: 0.3;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: url(./../../../public/about/Fantasy-3.png);
    background-repeat: no-repeat;
    background-size: cover;
  }

  @include section-fade-top;

  &__fade {
    @include section-fade-bottom;
  }

  &__buttons {
    margin: auto;
  }

  &__content {
    margin: auto;
    max-width: 1400px;
  }

  &__title {
    text-align: center;
    margin-bottom: 40px;
  }

  &__cards {
    display: flex;
    flex-direction: column;
  }

  &__top,
  &__bottom {
    flex: 1;
  }

  &__bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    flex-wrap: wrap;
  }

  &__top {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;

    padding-inline: 50px;

    p {
      margin-bottom: 20px !important;
    }
  }

  &__card {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    z-index: 0;

    width: 100%;
    max-width: 400px;
    min-height: 230px;
    max-height: 280px;
    border-radius: 20px 90px 20px 90px;
    padding: 50px 36px;
    margin-bottom: 40px;

    color: #20232a;
    background-image: linear-gradient(to top right, rgb(255 255 255 / 61%), rgb(255, 255, 255));

    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.1);

    transition-duration: 0.2s;
    transition-property: background-color;
  }

  // &__cardy {
  //   &:nth-last-of-type(1) .ecosystem__card {
  //     background-image: linear-gradient(to top right, #a6e070, #eea9a9);
  //   }

  //   &:nth-last-of-type(2) .ecosystem__card {
  //     background-image: linear-gradient(to top right, #a39fe0, #e9db98);
  //   }

  //   &:nth-last-of-type(3) .ecosystem__card {
  //     background-image: linear-gradient(to top right, #90e6c5, #c1f1aa);
  //   }
  // }

  &__card-title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    font-family: "Rubik", cursive;

    line-height: 41px;
    margin-bottom: 13px;

    letter-spacing: 0.08em;
    text-transform: capitalize;
  }

  &__card-content {
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.08em;
    font-weight: var(--font-weight-regular);

    max-width: 100%;
  }

  &__illustration {
    position: absolute;
    bottom: 20px;
    right: 0;

    z-index: -2;
    width: 170px;
    opacity: 0.8;

    &--staking {
      opacity: 0.6;
      bottom: 35px;
    }
  }

  h4,
  p {
    margin: 0;
  }

  @media screen and (max-width: 992px) {
    .ecosystem__card {
      min-height: 0;
    }
    .ecosystem__cards {
      flex-direction: column;
    }

    .ecosystem__bottom {
      margin-top: 40px;
      align-items: center;
      justify-content: center;
    }

    .ecosystem__cardy {
      &:nth-of-type(2) {
        .ecosystem__card {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &:nth-of-type(3) {
        .ecosystem__card {
          margin-left: auto;
        }
      }
    }
  }

  @media screen and (max-width: 770px) {
    padding: 50px 20px 100px 20px;
    height: auto;
    .ecosystem__card {
      margin-bottom: 20px;
      padding: 36px 40px 36px 36px;
      box-sizing: border-box;
    }

    .ecosystem__title {
      text-align: center;
    }

    .ecosystem__top {
      text-align: center;
    }
  }

  @media screen and (max-width: 676px) {
    &__card {
      &:first-of-type,
      &:last-of-type {
        align-self: center !important;
      }
    }
  }
}

.link {
  text-decoration: none;
}
