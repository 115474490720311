@import "./../../theme/mixins/section-fade-bottom";
@import "./../../theme/mixins/section-fade-top";

.preview {
  position: relative;
  padding: 100px 50px;
  height: 100vh;
  scroll-snap-align: start;
  &::before {
    content: '';
    z-index: 0;
    opacity: 0.3;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: url(./../../../public/about/Fantasy-4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 49%;
  }

  @include section-fade-top;

  &__wrapper {
    margin: 0 auto;

    width: 100%;
    max-width: 1400px;

    text-align: center;
  }

  &__title {
    margin-bottom: 40px;
  }

  &__text {
      color: #ffffff;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 50px;
  }


  &__card {
    cursor: grab;
    margin-right: 60px;

    max-height: 400px;
    max-width: 300px;

    img {
      pointer-events: none;
      width: 250px;
      margin: auto;
    }

    &:active {
      cursor: grabbing;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__fade {
    @include section-fade-bottom;
  }

  &__balloons {
    position: absolute;
    right: 100px;
    top: 0;
  }

  &__balloon {
    position: absolute;
    top: 0;
    right: 0;

    z-index: -1;

    animation-duration: 3s;
    animation-name: balloon-float;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;

    &--1 {
      right: 100px;
      top: -10px;
    }

    &--2 {
      top: 20px;
      right: 350px;
      opacity: 0.5;
    }

    &--3 {
      top: 200px;
      right: 40px;
      opacity: 0.5;
    }

    &--4 {
      top: 240px;
      right: 280px;
      opacity: 0.5;
    }
  }


  @media screen and (max-width: 770px) {
    text-align: center;
    padding: 20px 20px 50px 20px;

    &__balloons {
      display: none;
    }

    &::before {
      background-position: left;
    }

    &::after {
      display: none;
    }
  }

  @media screen and (max-width: 450px) {
    .title {
      margin-bottom: 50px;
    }
  }
}

@keyframes balloon-float {
  from {
    transform: translateY(-8px);
  }

  to {
    transform: translateY(8px);
  }
}