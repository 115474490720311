@import "./../../theme/mixins/section-fade-bottom";
@import "./../../theme/mixins/section-fade-top";

.roadmap {
  position: relative;
  padding: 50px;
  scroll-snap-align: start;
  height: 100vh;
  &::before {
    content: "";
    z-index: 0;
    opacity: 0.3;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: url(./../../../public/about/Fantasy-2.png);
    background-repeat: no-repeat;
    background-size: cover;
  }

  @include section-fade-top;

  // &::after {
  //   content: '';

  //   position: absolute;
  //   bottom: 0;
  //   left: 0;

  //   width: 100%;
  //   height: 100px;

  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-position: top center;

  //   background-image: linear-gradient(to top, #232631, transparent);
  // }

  &__wrapper {
    margin: 50px auto;

    width: 100%;
    max-width: 1400px;
  }

  &__title {
    margin-bottom: 40px;
    text-align: center;
  }

  &__fade {
    @include section-fade-bottom;
  }

  .phases {
    position: relative;

    display: flex;
    flex-direction: row;
    overflow: auto hidden;

    padding: 20px 0;

    .phase {
      position: relative;
      z-index: 1;

      height: 300px;
      width: fit-content;

      overflow: hidden;
      margin-right: 50px;

      border-radius: 20px;
      background-color: white;

      &__animation {
        position: absolute;
        right: 0;
        top: 0;

        z-index: 0;
        opacity: 0.1;
        width: 50%;
      }

      &__content {
        z-index: 1;
        width: 500px;
        padding: 30px;
      }

      &__title {
        font-size: 30px;
        margin-bottom: 8px;
        color: #232631;
        font-family: "Rubik", cursive;
        font-weight: var(--font-weight-bold);
        text-align: left;
      }

      &__detail {
        z-index: 1;
        color: #232631;
        margin-block: 10%;
      }

      &__list {
        margin: 0;
        z-index: 1;
        padding: 0 0 0 24px;
      }

      &__item {
        z-index: 1;
      }

      &--foundation {
        background-image: linear-gradient(to bottom right, #f19d96, #f1cea0);
      }

      &--prelaunch {
        background-image: linear-gradient(to bottom right, #e8ffc4, #c0e0f5);
      }

      &--postlaunch {
        background-image: linear-gradient(to bottom right, #f1e49a, #72655c);

        .phase__title,
        .phase__detail {
          font-weight: 300;
          color: #232631;
        }

        .phase__animation {
          opacity: 0.4;
          filter: grayscale(1) invert(1);
        }
      }

      &--mainstream {
        background-image: linear-gradient(to bottom right, #eef5ff, #a0aeff);

        .phase__title,
        .phase__detail {
          font-weight: 300;
          color: #232631;
        }

        .phase__animation {
          opacity: 0.4;
          filter: grayscale(1) invert(1);
        }
      }
    }
  }

  @media screen and (max-width: 770px) {
    &__title {
      text-align: center;
    }
  }
}
