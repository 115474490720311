.socials {
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: center;

  &__title {
    position: relative;
    left: -5px;
  
    margin-bottom: 40px;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    display: flex;
    align-items: center;
  }

  &__item {
    display: inline;
    cursor: pointer;
  
    padding: 8px;
    margin: 0 8px;

    border-radius: 50%;
    border: 1px solid #ffffff;

    width: 25px;
    height: 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition-duration: 0.2s;
    transition-property: background-color;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--telegram {
      .socials__logo {
        position: relative;
        left: -2px;
        top: 1px;
      }
    }

    &--gitbook {
      .socials__logo {
        position: relative;
        top: 1px;
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);

      .socials__logo {
        transform: rotate(-10deg);
      }
    }
  }

  &__logo {
    width: 25px;
    height: 25px;

    transition-duration: 0.2s;
    transition-property: transform;
  }
  
  @media screen and (max-width: 770px) {
    padding: 0 50px 0 50px;
  }
}