.partnerships {
  &__title {
    margin-bottom: 40px;

    @media screen and (max-width: 950px) {
      text-align: center;
    }
  }

  .row {
    color: white;
    margin-bottom: 15px;

    .sub-title {
      font-size: 14px;
      font-weight: var(--font-weight-bold);

      line-height: 19px;
      letter-spacing: 0.08em;

      margin: 0 0 12px 0;

      @media screen and (max-width: 950px) {
        text-align: center;
      }
    }

    .logo {
      display: block;
      margin-top: 5px;

      &--opbnb {
        width: 120px;
        margin-left: -10px;
      }

      @media screen and (max-width: 950px) {
        margin: auto;
        width: 200px;
      }
    }
  }

  @media screen and (max-width: 770px) {
    padding: 0;

    .row {
      @media screen and (max-width: 770px) {
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
          max-width: 300px !important;
        }
        
      }

      
    }
  }
}