body {
  margin: 0;
  overflow-x: hidden;

  font-family: var(--font-name);
  font-weight: var(--font-weight-regular);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  transition-duration: 0.2s;
  transition-property: background-color;

  background-color: #20232a;

  overflow-y: hidden;
  
}

#container {
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  scroll-snap-type: y proximity;
}